<template>
  <div class="TopStatusLeft">
    <def-loading
      :setStyle="{ position: 'fixed' }"
      :isshow="isshow"
    ></def-loading>
    <div class="orderId center">
      {{$t('Paycenter.Pages.OrderId')}}：{{ orderDetil.order_detail.order_id }}
    </div>

    <div class="status center">{{ orderDetil.order_detail.status_text }}</div>

    <!-- 已完成 -->
    <div class="finishBtn" v-if="status == 'finish'">
      <div class="box">
        <div class="item lookinvoice">
          <span>{{$t('Ordertail.Common.CheckInvoice')}}</span>
        </div>
        <div class="item evaluate">
          <span>{{$t('Ordertail.Common.Evaluate')}}</span>
        </div>
        <div class="btn" @click="BuyAgain">
          <span>{{$t('Ordertail.Common.Repurchase')}}</span>
        </div>
      </div>

      <div class="print center">
        <span> <i class="iconfont icon-dayinji_o"></i>{{$t('Ordertail.Common.PrintInvoice')}}</span>
      </div>
    </div>

    <!-- 待支付 -->
    <div class="awaitPay" v-if="status == 'await_pay'">
      <div class="time">
        <p style="margin-bottom: 0.5rem">
          <i class="iconfont icon-shijian"></i>剩余{{ time.h }}时{{ time.m }}分
        </p>
        <timer deadline="2021-11-19 02:37:32" :time.sync="time"></timer>
      </div>
      <div class="box">
        <div class="item pay" @click="pay">
          <span>{{$t('Ordertail.Common.Payment')}}</span>
        </div>
        <div class="item lookinvoice">
          <span>{{$t('Ordertail.Common.CheckInvoice')}}</span>
        </div>
        <div class="item changeOrder" @click="cancelOrder">
          <span>{{$t('User.Orders.Button.CancelOrder')}}</span>
        </div>
        <div class="btn" @click="BuyAgain">
          <span>{{$t('Ordertail.Common.Repurchase')}}</span>
        </div>
      </div>

      <div class="print center">
        <span> <i class="iconfont icon-dayinji_o"></i>{{$t('Ordertail.Common.PrintOrder')}}</span>
        <span @click="cancelOrder">
          <i class="iconfont icon-quxiao"></i>{{$t('User.Orders.Button.CancelOrder')}}</span
        >
      </div>
    </div>

    <!-- 待收货 -->
    <div class="shipped" v-if="status == 'shipped'">
      <div class="box">
        <div class="item reminder">
          <span>{{$t('Ordertail.Common.Reminder')}}</span>
        </div>
        <div class="item lookinvoice">
          <span>{{$t('Ordertail.Common.CheckInvoice')}}</span>
        </div>
        <div class="btn" @click="BuyAgain">
          <span>{{$t('Ordertail.Common.Repurchase')}}</span>
        </div>
      </div>

      <div class="print center">
        <span> <i class="iconfont icon-dayinji_o"></i>{{$t('Ordertail.Common.PrintOrder')}}</span>
        <span @click="cancelOrder">
          <i class="iconfont icon-quxiao"></i>{{$t('User.Orders.Button.CancelOrder')}}</span
        >
      </div>
    </div>

    <!-- 待发货 -->
    <div class="await_ship" v-if="status == 'await_ship'">
      <div class="box">
        <div class="item reminder">
          <span>{{$t('Ordertail.Common.Reminder')}}</span>
        </div>
        <div class="item lookinvoice">
          <span>{{$t('Ordertail.Common.CheckInvoice')}}</span>
        </div>
        <div class="btn" @click="BuyAgain">
          <span>{{$t('Ordertail.Common.Repurchase')}}</span>
        </div>
      </div>
      <div class="print center">
        <span> <i class="iconfont icon-dayinji_o"></i>{{$t('Ordertail.Common.PrintOrder')}}</span>
        <span @click="cancelOrder">
          <i class="iconfont icon-quxiao"></i>{{$t('User.Orders.Button.CancelOrder')}}</span
        >
      </div>
    </div>

    <!-- 已取消 -->
    <div class="cancelBtn" v-if="status == 'canceled'">
      <div class="btn" @click="BuyAgain">
        <span>{{$t('Ordertail.Common.Repurchase')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Order from "@/model/Order.js";
import { BatchAdd } from "@/model/Cart.js";
import Timer from "common/time/Timer";
export default {
  name: "TopStatusLeft",
  data() {
    return {
      time: {},
      isshow: false,
    };
  },
  computed: {
    status() {
      let status = this.orderDetil.order_detail.status;

      //完成
      if (status == "CONFIRMED") {
        return "finish";
      }
      //已取消
      else if (
        status == "CANCELED" ||
        status == "已取消" ||
        status == "Canceled"
      ) {
        return "canceled";
      }
      //待支付
      else if (status == "AWAIT_PAY") {
        return "await_pay";
      }
      //待发货
      else if (status == "AWAIT_SHIP") {
        return "await_ship";
      }
      //待收货
      else if (status == "SHIPPED") {
        return "shipped";
      }
    },
  },
  created() {},
  components: {
    Timer,
  },
  methods: {
    // 付款
    pay() {
      this.$router.push({
        path: "/paycenter/index",
        query: {
          order_id: this.orderDetil.order_detail.order_id,
        },
      });
    },

    // 再次购买
    BuyAgain() {
      this.isshow = true;
      BatchAdd({
        data: {
          order_id: this.orderDetil.order_detail.order_id,
        },
        succ: (res) => {
          //获取购物车列表数据
          this.$store
            .dispatch("getCart")
            .then((res) => {
              this.isshow = false;
              this.$router.push("/cart/index");
            })
            .catch((err) => {
              this.isshow = false;
              this.$message("error", err);
            });
        },
        fail: (res, all) => {
          this.isshow = false;
          this.$message("error", all.msg);
        },
      });
    },

    //取消订单
    cancelOrder(order_id) {
      this.$confirm(
        "danger",
       this.$t('Common.Confirm.Title'),
       this.$t('User.Balance.Msg.CancelOrder'),
        true,
        null,
        this
      )
        .then((ele) => {
          Order.Cancel({
            data: {
              order_id: this.orderDetil.order_detail.order_id,
            },
            succ: (res, all) => {
              ele.close();
              // this.$root.$children[0].$refs.activeCom.getOrderDetil();
              this.$message("success", all.msg);
              location.reload();
            },
            fail: (res, all) => {
              ele.close();
              this.$message("error", all.msg);
            },
            
          });
        })
        .catch(() => {});
    },
  },
  props: {
    orderDetil: Object,
  },
};
</script>
<style lang='scss' scoped>


@mixin btn {
  .btn {
    cursor: pointer;
    width: 7rem;
    height: 2rem;
    font-size: 0.75rem;
    box-sizing: border-box;
    margin: 0 auto;
    border: 1px solid var(--red-color);
    color: var(--red-color);
    text-align: center;
    line-height: calc(2rem - 2px);
  }
}

.TopStatusLeft {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .center {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @mixin font {
    font-size: 0.75rem;
    color: var(--text-color-default);
  }

  .orderId {
    @include font;
  }

  .status {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--green-color);
    margin: 3rem 0;
  }

  .finishBtn {
    @include btn;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        width: 7rem;
        height: 2rem;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }

      .lookinvoice {
        border: 1px solid #e6e6e6;
        font-size: 0.75rem;
        color: var(--text-color-title);
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .evaluate {
        margin-bottom: 0.5rem;
        border: 1px solid var(--red-color);
        font-size: 0.75rem;
        color: var(--red-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
      }
    }
  }

  .awaitPay {
    @include btn;
    .time {
      p {
        text-align: center;
        font-size: 0.75rem;
        i {
          margin-right: 0.2rem;
        }
      }
    }
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        width: 7rem;
        height: 2rem;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }

      .lookinvoice {
        border: 1px solid #e6e6e6;
        font-size: 0.75rem;
        color: var(--text-color-title);
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .pay,
      .changeOrder {
        border: 1px solid var(--red-color);
        font-size: 0.75rem;
        color: var(--red-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0;
      }
    }
  }

  .shipped,
  .await_ship {
    @include btn;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        width: 7rem;
        height: 2rem;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }

      .lookinvoice {
        margin-bottom: 0.5rem;
        border: 1px solid #e6e6e6;
        font-size: 0.75rem;
        color: var(--text-color-title);
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .reminder {
        border: 1px solid var(--red-color);
        font-size: 0.75rem;
        color: var(--red-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0;
      }
    }
  }

  .cancelBtn {
    @include btn;
  }

  .print {
    span {
      cursor: pointer;
      margin: 0 2rem;
    }
    margin-top: 4.5rem;
    font-size: 0.75rem;
    color: var(--text-color-title);
    align-items: center;
    i {
      margin-right: 0.2rem;
      font-size: 0.875rem;
    }
  }
}
</style>
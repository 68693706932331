<template>
  <div class="topstatus">
    <div class="main">
      <div class="left">
        <top-status-left :orderDetil="orderDetil"></top-status-left>
      </div>
      <div class="right">
        <top-status-right :orderDetil="orderDetil" :logiquery="logiquery"></top-status-right>
      </div>
    </div>
    <div class="mb"></div>
  </div>
</template>
<script>
import { TopStatusLeft, TopStatusRight } from "./topStatusChild";
export default {
  name: "TopStatus",
  data() {
    return {};
  },
  created() {},
  components: {
    TopStatusLeft,
    TopStatusRight,
  },
  props: {
    orderDetil: Object,
    logiquery:Object
  },
};
</script>
<style lang='scss' scoped>

.topstatus {
  border-top: 4px solid var(--green-color);

  .main {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    .left {
      width: 30%;
      box-sizing: border-box;
      border-right: 1px solid var(--border-color-default);
    }
    .right {
      width: 70%;
      box-sizing: border-box;
    }
  }

  .mb {
    height: 4px;
    background-image: url("~@/assets/img/ordertail/order-icons.png");
  }
}
</style>
<template>
  <div class="ordertail">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">HOME</my-breadcrumb-item>
        <my-breadcrumb-item to="/member/orders"
          >{{$t('Common.NavTitle.MyOrders')}}</my-breadcrumb-item
        >
        <my-breadcrumb-item :active="true" :isDisabled="true"
          >{{$t('Paycenter.Pages.OrderId')}}: {{ orderId }}</my-breadcrumb-item
        >
      </my-breadcrumb>

      <div class="main" v-if="orderDetil.order_detail">
        <top-status :orderDetil="orderDetil" :logiquery="logiquery"></top-status>

        <logistics-detil v-if="showLogistics" :orderDetil="orderDetil"  :logiquery="logiquery"></logistics-detil>

        <older-info :orderDetil="orderDetil" :logiquery="logiquery"></older-info>
        <older-goods :orderDetil="orderDetil"></older-goods>
      </div>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
import { TopStatus, LogisticsDetil, OlderInfo, OlderGoods } from "./child";
import Order from "@/model/Order.js";
import User from "@/model/User.js";
export default {
  name: "Ordertail",
  data() {
    return {
      //订单详情
      orderDetil: {},

      //物流明细
      logiquery: {
        // logistics:true
      },
    };
  },
  created() {
    this.getOrderDetil();
    this.getLogiqueryInfo();
  },

  computed: {
    //订单单号
    orderId() {
      return this.$route.query.order_id;
    },

    //当订单处于完成、待支付、待收货时显示
    showLogistics() {
      if (this.orderDetil.order_detail) {
        let status = this.orderDetil.order_detail.status;
        switch (status) {
          case "CONFIRMED":
            return true;
          case "AWAIT_PAY":
            return true;
          case "SHIPPED":
            return true;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    //获取订单详情数据
    getOrderDetil() {
      User.Order.Detail({
        data: {
          order_id: this.orderId,
        },
        succ: (res) => {
          this.orderDetil = res;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },

    //查询物流信息
    getLogiqueryInfo() {
      Order.Logi({
        data: {
          order_id: this.orderId,
        },
        succ: (res) => {
          this.logiquery=res
        },
        fail: (res, all) => {
          this.logiquery = {}
          // this.$message("error", all.msg);
        },
        
      });
    },
  },
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
    TopStatus,
    LogisticsDetil,
    OlderInfo,
    OlderGoods,
  },
};
</script>
<style lang='scss' scoped>

.ordertail {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--bg-color-hint);

  .main {
    margin-top: 1.5rem;
  }
}
</style>
<template>
  <div class="timeline" ref="timeline">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Timeline",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
        let nodes = this.$refs.timeline.children
      //设置倒序和正序
      if (this.reverse) {
        let frag = document.createDocumentFragment();
        let arr = [...nodes];
        arr.reverse();
        arr.forEach((item) => {
          frag.appendChild(item);
        });
        this.$refs.timeline.appendChild(frag);
      }

      //移除最下方节点的竖线
      let needremove = nodes[nodes.length-1].querySelector('.status').querySelector('.line')
      needremove.parentElement.removeChild(needremove)
    

    },
  },
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
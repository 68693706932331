<template>
  <div class="logistics">
    <div class="left">
      <logistics-detil-left :logiquery="logiquery" :orderDetil="orderDetil"></logistics-detil-left>
    </div>
    <div class="right">
      <logistics-detil-right :logiquery="logiquery" :orderDetil="orderDetil"></logistics-detil-right>
    </div>
  </div>
</template>
<script>
import { LogisticsDetilLeft, LogisticsDetilRight } from "./logisticsDetilChild";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    LogisticsDetilLeft,
    LogisticsDetilRight,
  },

  props: {
    logiquery: Object,
    orderDetil:Object
  },
};
</script>
<style lang='scss' scoped>

.logistics {
  margin-top: 1.25rem;
  display: flex;
  height: 16rem;
  background-color: white;



  > .right {
    box-sizing: border-box;
    width: 70%;
  }

  > .left {
    border-right: 1px solid var(--border-color-default);
    width: 30%;
  }
}
</style>
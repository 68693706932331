<template>
  <div class="step">
    <div class="icon" v-if="icon != 'noIcon'">
      <i class="iconfont" :class="[icon]" :style="{ fontSize: iconSize }"></i>
    </div>

    <div class="font" :style="{ fontSize: fontSize }">
      <div class="title">
        {{ title }}
      </div>

      <div class="decripe">
        {{ decripe }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    icon: {
      type: String,
      default: "noIcon",
    },
    title: String,
    decripe: String,
    fontSize: {
      type: String,
      default: "0.75rem",
    },

    iconSize: {
      type: String,
    },
  },
};
</script>
<style lang='scss' scoped>
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;

  .icon {
    color: #cdcdcd;
    width: 2.5rem;
    height: 1.8rem;
    font-size: 1.5rem;
    text-align: center;
    //   margin-right:0.75rem;
  }
  .font {
    .title,
    .decripe {
      color: #71778e;
      text-align: center;
    }

    .title {
      margin-bottom: 0.1rem;
    }
    margin-top: 0.75rem;
    max-width: 5rem;
  }
}

.active {
  .icon {
    color: #7bbc52;
    font-size: 1.5rem;
  }
}
</style>
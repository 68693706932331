<template>
  <div class="right">
    <div class="list">
      <div class="content">
        <timeline v-if="logiquery.logistics">
          <timeline-item
            :time="item.time"
            v-for="item in logiquery.logistics"
            :key="item.time"
            >{{ item.context }}</timeline-item
          >
        </timeline>


        <!-- 待支付 -->
         <timeline v-if="!logiquery.logistics">
          <timeline-item
            :time="orderDetil.order_detail.create_time_text"
            >{{$t('Ordertail.Msg.WaitConfirmation')}}</timeline-item
          >
        </timeline>

      </div>
    </div>
  </div>
</template>
<script>
import { Timeline, TimelineItem } from "common/timeline";
export default {
  name: "LogisticsDetilRight",
  data() {
    return {
     
    };
  },
  created() {},
  components: {
    Timeline,
    TimelineItem,
  },
  props:{
      logiquery:Object,
      orderDetil:Object
  }
};
</script>
<style lang='scss' scoped>

.right {
    .list{
        height: 13.5rem;
        margin:20px 10px 20px 20px;
        overflow: auto;
    }
}
</style>
<template>
  <div class="TopStatusRight">
    <div class="decripe">
      <!-- 订单已经完成,感谢你在京东购物，欢迎评价 -->
    </div>

    <div class="flow">
      <steps
        :active="active"
        v-if="
          status != 'CANCELED' && status != '已取消' && status != 'Canceled'
        "
      >
        <step
          icon="icon-diandan"
          :title="$t('Cart.Checkout.Pages.PlaceOrder')"
          iconSize="1.875rem"
          :decripe="
            orderDetil.order_detail && orderDetil.order_detail.create_time_text
          "
        ></step>
        <step
          icon="icon-fukuanchenggong"
          :title="$t('App.Rcom.Pages.AWAIT_SHIP')"
          iconSize="2rem"
          decripe=""
        ></step>
        <step
          icon="icon-goodswhoutStock"
          :title="$t('Ordertail.Common.OutboundGoods')"
          iconSize="1.875rem"
          decripe=""
        ></step>
        <step
          icon="icon-wuliu"
          :title="$t('User.Orders.Pages.Delivering')"
          iconSize="1.875rem"
          decripe=""
        ></step>
        <step
          icon="icon-pass-1-copy"
          :title="$t('Ordertail.Common.Finish')"
          iconSize="1.875rem"
          decripe=""
        ></step>
      </steps>

      <steps
        :active="active"
        v-if="
          status == 'CANCELED' || status == '已取消' || status == 'Canceled'
        "
      >
        <step
          icon="icon-diandan"
          :title="$t('Ordertail.Common.SubmitApplications')"
          iconSize="1.875rem"
          decripe="2021-09-27 15:26:10"
        ></step>
        <step
          icon="icon-zhengzaichuli"
          :title="$t('Ordertail.Common.CancelProcess')"
          iconSize="1.875rem"
          decripe=""
        ></step>
        <step
          icon="icon-approval"
          :title="$t('Ordertail.Common.approved')"
          iconSize="2rem"
          decripe=""
        ></step>
        <step
          icon="icon-pass-1-copy"
          :title="$t('Ordertail.Common.Finish')"
          iconSize="1.875rem"
          decripe=""
        ></step>
      </steps>
    </div>
  </div>
</template>
<script>
import { Step, Steps } from "common/steps";
export default {
  name: "TopStatusRight",
  data() {
    return {};
  },
  created() {},
  computed: {
    status() {
      return this.orderDetil.order_detail.status;
    },
    active() {
      let status = this.orderDetil.order_detail.status;

      //完成
      if (status == "CONFIRMED") {
        return 5;
      }
      //已取消
      else if (
        status == "CANCELED" ||
        status == "已取消" ||
        status == "Canceled"
      ) {
        return 4;
      }
      //待支付
      else if (status == "AWAIT_PAY") {
        return 1;
      }
      //待发货
      else if (status == "AWAIT_SHIP") {
        return 2;
      }
      //待收货
      else if (status == "SHIPPED") {
        //查看当前有无物流数据，有则定位到等待收货，没有则定位到商品出库
        if (this.logiquery.logistics != "") {
          return 4;
        }
        return 3;
      }
    },
  },
  components: {
    Step,
    Steps,
  },
  props: {
    orderDetil: Object,
    logiquery: Object,
  },
};
</script>
<style lang='scss' scoped>

.TopStatusRight {
  padding: 1.5rem 1.5rem;
  .decripe {
    font-size: 0.75rem;
    color: var(--text-color-default);
    margin-bottom: 4rem;
  }

  .flow {
    padding: 2.5rem;
    display: flex;
    justify-content: center;
  }
}
</style>
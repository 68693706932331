<template>
  <div class="Steps" ref="steps">
    <div class="main" ref="main">
      <slot></slot>
    </div>
    <div class="line" ref="line"></div>
    <i class="iconfont icon" ref="icon"></i>
  </div>
</template>
<script>
export default {
  name: "Steps",
  data() {
    return {};
  },
  watch: {
    active() {
      this.init();
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //如果active更新则需要先移除之前创建的横线节点且移除原有的单个节点样式
      let arr = [...this.$refs.main.querySelectorAll(".proce")];
      if (arr.length != 0) {
        arr.forEach((item) => {
          item.parentElement.removeChild(item);
        });

        let tem = [...this.$refs.main.children];
        for (let i = 0; i < tem.length; i++) {
          tem[i].classList.remove("active");
        }
      }

      let target = [...this.$refs.main.children];

      // 单个横线的长度
      let itemW = this.lineWidth(target)

      let lineArr = [];
      for (let i = 0; i < target.length - 1; i++) {
        let div = this.$refs.line.cloneNode(true);
        let divIn = this.$refs.line.cloneNode(true);
        let icon = this.$refs.icon.cloneNode(true);
        div.classList.add("proce");
        
        div.style.width = itemW()+'px'

        div.classList.add("nomal");
        divIn.classList.add("line");
        icon.classList.add("icon-jiantouyou");
        divIn.appendChild(icon);
        div.appendChild(divIn);
        lineArr.push(div);
        this.$refs.main.insertBefore(div, target[i + 1]);
      }

      //根据进度渲染主体图标
      for (let i = 0; i < this.active; i++) {
        target[i].classList.add("active");
      }

      //根据进度渲染线条
      if (this.active == 1) {
        return;
      } else {
        for (let i = 0; i < this.active - 1; i++) {
          lineArr[i].classList.remove("nomal");
          lineArr[i].classList.add("complete");
        }
      }
    },

    //计算每个横线的长度
    lineWidth(arr){
      let itemNodeW = 5 * 16

      //父元素的内容区宽度
      let w = this.$refs.steps.parentElement.clientWidth - 2 * 2.5*16
      
      //余量
      let emp = 20

      return ()=>{
        return  (w-emp-itemNodeW*arr.length)/(arr.length-1)
      }
  
    }
  },
  props: {
    active: {
      type: Number,
    },

    type: {
      type: String,
    },
  },
};
</script>
<style lang='scss' scoped>
.Steps {
  .main {
    display: flex;
  }
}

.proce {
  margin-top: 0.7rem;
  // width: 5.5rem;
  // height: 9px;
  // background-size: 5.5rem 110px;
  // background-image: url("~@/assets/img/ordertail/proce-icons.png");
  // width: 5rem;
  height: 2px;
  // background-color: #cdcdcd;
  .line {
    position: relative;
    margin-left: -0.75rem;
    margin-right: -0.5rem;
    height: 2px;
    text-align: right;
    position: relative;

    .icon {
      font-size: 0.75rem;
      position: absolute;
      right: -10px;
      top: -6px;
    }
  }
}

.nomal {
  // background-position: 0 -19px;
  .line {
    background-color: #cdcdcd;
    .icon {
      color: #cdcdcd;
    }
  }
}

.complete {
  // background-position: 0 -38px;

  .line {
    .icon {
      color: #7bbc52;
    }

    background-color: #7bbc52;
  }
}
</style>
<template>
  <div class="OlderGoods">
    <table class="goodlist">
      <thead>
        <tr>
          <th>{{$t('Goods.Pages.ProductName')}}</th>
          <th>{{$t('Ordertail.Common.SKU')}}</th>
          <th>{{$t('Home.Pages.Price')}}</th>
          <th>{{$t('Ordertail.Common.Quantity')}}</th>
          <!-- <th>京豆</th> -->
          <!-- <th>{{$t('Ordertail.Common.Operation')}}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr class="item" v-for="item in orderDetil.order_detail.goods_list" :key="item.goods_id">
          <td>
            <LandR
              padding="0 1rem"
              :proportion="['', '']"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <template v-slot:left>
                <div class="img">
                  <img :src="item.image" alt="" />
                </div>
              </template>
              <template v-slot:right>
                <div class="font">
                  <div class="title font_two_hide">
                   {{ $cl('name',item,$i18n.locale)}}
                  </div>
                  <div class="specification font_hide">{{item.bn}}</div>
                </div>
              </template>
            </LandR>
          </td>
          <td class="centerinfo">{{item.goods_id}}</td>
          <td class="centerinfo">{{item.price_text}}</td>
          <td class="centerinfo">{{item.num}}</td>
       
          <!-- <td>
            <div class="btn">
              <i class="iconfont icon-shandian"></i><span>{{$t('Goods.Pages.BuyNow')}}</span>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>

    <div class="price">
      <div class="info">
        <div class="item">
          <span class="title">{{$t('User.Orders.Pages.GoodsAmount')}}：</span><span class="num">{{orderDetil.order_detail.goods_amount_text}}</span>
        </div>
        <div class="item">
          <span class="title">{{$t('Cart.Checkout.Pages.Shipping')}}：</span><span class="num">{{orderDetil.order_detail.shipping_amount_text}}</span>
        </div>
        <div class="item">
          <span class="title">{{$t('Cart.Checkout.Pages.PointDeduction')}}：</span><span class="num">-${{orderDetil.order_detail.point_deduction}}</span>
        </div>
        <div class="item">
          <span class="title">{{$t('User.Orders.Pages.CouponDeduction')}}：</span><span class="num">-{{orderDetil.order_detail.pmt_amount_text}}</span>
        </div>
        <div class="item">
          <span class="title">{{$t('User.Orders.Pages.AllTotal')}}：</span><span class="num">{{orderDetil.order_detail.total_amount_text}}</span>
        </div>

        <!-- <div class="item BalanceDeduction">
          <span class="title">{{$t('Cart.Checkout.Pages.BalanceDeduction')}}：</span><span class="num">-${{orderDetil.order_detail.balance_deduction}}</span>
        </div>
        <div class="item red">
          <span class="title">{{$t('SettleInfo.Payment')}}：</span><span class="num buyPic">{{orderDetil.order_detail.remain_needpay_amount_text}}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import LandR from "common/layout/LandR";
export default {
  name: "OlderGoods",
  data() {
    return {};
  },
  created() {},
  components: {
    LandR,
  },
  props:{
    orderDetil:Object
  }
};
</script>
<style lang='scss' scoped>

.OlderGoods {
  margin-top: 1.25rem;
  background-color: white;
  .goodlist {
    width: 100%;
    border-collapse: collapse;
    color: var(--text-color-title);

    td {
      vertical-align: top;
      padding: 1rem 0;
    }

    .item {
      font-size: 0.75rem;
      border-bottom: 1px solid var(--border-color-default);
      .img {
        max-width: 4rem;
        img {
          width: 100%;
        }
      }

      .font {
        max-width: 22.5rem;
        margin-left: 0.875rem;

        .specification {
          margin-top: 0.35rem;
          color: var(--text-color-default);
        }
      }

      .centerinfo {
        text-align: center;
      }

      .btn {
        height: 1.75rem;
        width: 80%;
        cursor: pointer;
        box-sizing: border-box;
        margin: 0.3rem auto;
        line-height: 1.75rem;
        text-align: center;
        background-color: #f5f5f5;
        border: 1px solid #e9e9e9;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .price{
      margin-top:1.5rem;
      display: flex;
      justify-content: flex-end;
      .info{
          margin-right:1rem;
          width: 15rem;
          .item{
              margin-bottom:1rem;
              font-size: 0.75rem;
                color: var(--text-color-default);
              display: flex;
              .title,.num{
                  text-align: right;
              }
              .title{ 
                  width: 50%;
              }
              .num{
                  width: 50%;
              }

              .buyPic{
                  font-size: 1.25rem;
                  font-weight: 600;
              }
          }

          .BalanceDeduction{
            border-top:1px solid var(--border-color-default);
            padding-top:15px;
          }

          .red{
              color: var(--red-color);
              align-items: center;
          }
      }
  }
}

th {
  background-color: #f9f9f9;
  padding: 0.875rem;
  font-size: 0.75rem;
  font-weight: 400;
}
</style>
<template>
  <div class="left">
    <div class="img">
      <img :src="orderDetil.order_detail.goods_list[0].image" alt="" />
    </div>
    <div class="decripe">
      <div class="distributionMeth item">{{$t('Ordertail.Common.Delivery')}}：<span>{{orderDetil.order_detail && orderDetil.order_detail.shipping_name}}</span></div>
      <div class="delivery item">
        {{$t('Ordertail.Common.Carrier')}}：<span class="name">{{setLogi}}</span
        ><span class="consult" v-if="!!logiquery.basic">{{$t('Ordertail.Common.ExpressConsulting')}}</span>
      </div>
      <div class="orderId item" v-if="!!logiquery.basic">{{$t('Ordertail.Common.Waybill')}}：<span>{{logiquery.basic.logi_no}}</span></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LogisticsDetilLeft",
  data() {
    return {};
  },
  computed:{
    setLogi(){
      return !!this.logiquery.basic ? this.logiquery.basic.logi_name : this.$t('Ordertail.Common.Express')
    }
  },
  created() {},
  props: {
    logiquery: Object,
    orderDetil:Object
  },
};
</script>
<style lang='scss' scoped>

.left {
  padding: 1.5rem 1.5rem;
  display: flex;

  .img {
    width: 6.25rem;
    img {
      width: 100%;
    }
  }

  .decripe {
    margin-left: 0.75rem;
    font-size: 0.75rem;
    color: var(--text-color-title);
    max-width: calc(100% - 7rem);

    .item {
      margin: 0.5rem 0;
    }
    .delivery {
      .consult {
        margin-left: 0.5rem;
        color: var(--red-color);
        cursor: pointer;
      }
    }
  }
}
</style>
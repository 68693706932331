<template>
  <div class="timelineItem">
    <div class="time">
      {{ time }}
    </div>
    <div class="status">
      <div class="ball"></div>
      <div class="line"></div>
    </div>
    <div class="decripe">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimelineItem",
  data() {
    return {};
  },
  created() {},
  props: {
    time: {
      type: String,
    },
  },
};
</script>
<style lang='scss' scoped>
.timelineItem {
  font-size: 0.75rem;
  color:#202435;
  display: flex;
  .time {
    width: 20%;
    text-align: right;
  }
  .status {
    width: 10%;
    padding-top: 0.25rem;
    .ball {
      width: 7px;
      height: 7px;
      margin: 0 auto;
      background-color: #dadada;
      border-radius: 50%;
    }

    .line {
      background-color: #dadada;
      width: 1px;
      margin: 0 auto;
      height: calc(100% - 7px + 0.25rem);
    }
  }
  .decripe {
    width: 70%;
    padding-bottom: 1.25rem;
  }
}
</style>
<template>
  <div class="LandR" :style="{padding:padding,justifyContent,alignItems}">
    <div class="left" :style="{ width: proportion[0] }">
      <slot name="left"></slot>
    </div>
    <div class="right" :style="{ width: proportion[1] }">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "LandR",
  data() {
    return {};
  },
  created() {},
  props: {
    //内边距
    padding: {
      type: String,
      default: "1.5rem",
    },

    //左右比例
    proportion: {
      type: Array,
      default: () => {
        return ["25%", "75%"];
      },
    },

    //主轴空白分配
    justifyContent:{
      type:String,
      default:'space-between'
    },

    //副轴空白分配
    alignItems:{
       type:String,
      default:'center'
    }
  },
};
</script>
<style lang='scss' scoped>
.LandR {
  display: flex;
  box-sizing: border-box;
}

</style>
<template>
  <div class="OlderInfo">
    <div class="left">
      <div class="title">{{$t('Cart.Checkout.ConsigneeInfo')}}</div>
      <div class="main">
        <div class="item">
          <div class="label">{{$t('User.Address.Name')}}：</div>
          <div class="info">{{orderDetil.order_detail.ship_name}}</div>
        </div>
        <div class="item">
          <div class="label">{{$t('User.Address.Addr')}}：</div>
          <div class="info">{{orderDetil.order_detail.ship_addr}}</div>
        </div>
        <div class="item">
          <div class="label">{{$t('User.Address.Mobile')}}：</div>
          <div class="info">{{orderDetil.order_detail.ship_mobile}}</div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="title">{{$t('Cart.Shipping')}}</div>
      <div class="main">
        <div class="item">
          <div class="label">{{$t('User.Orders.Pages.ShipCarrier')}}：</div>
          <div class="info">{{orderDetil.order_detail.shipping_name}}</div>
        </div>
        <!-- <div class="item">
          <div class="label">{{$t('User.Orders.Pages.ShippingDay')}}：</div>
          <div class="info">{{orderDetil.order_detail.ship_day}}</div>
        </div> -->
        <!-- <div class="item">
          <div class="label">期限配送时间：</div>
          <div class="info">09:00-21:00</div>
        </div> -->
      </div>
    </div>
    <div class="right">
      <div class="title">{{$t('Ordertail.Common.PaymentInformation')}}</div>
      <div class="main">
        <div class="item">
          <div class="label">{{$t('Ordertail.Common.Payment')}}：</div>
          <div class="info">{{orderDetil.order_detail.payment}}</div>
        </div>
        <div class="item" v-if="orderDetil.order_detail.pay_time">
          <div class="label">{{$t('Ordertail.Common.Paytime')}}：</div>
          <div class="info">{{orderDetil.order_detail.pay_time}}</div>
        </div>
      
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OlderInfo",
  data() {
    return {};
  },
  created() {},
  computed:{
    logi(){
       return !!this.logiquery.basic ? this.logiquery.basic.logi_name : this.$t('Ordertail.Common.Express')
    }
  },
  props:{
    orderDetil:Object,
    logiquery:Object
  }
};
</script>
<style lang='scss' scoped>

.OlderInfo {
  background-color: white;
  border-top: 4px solid #eeeeee;
  box-sizing: border-box;
  height: 16rem;
  margin-top: 1.25rem;
  display: flex;
  padding:1rem 1.5rem;
  .left,.center,.right{
      width: calc(100% / 3);
      box-sizing: border-box;
      padding:0rem 1.5rem;
   
  }

  .center{
      border-left: 1px solid var(--border-color-default);
      border-right: 1px solid var(--border-color-default);
  }

}


.title{
    color:var(--text-color-title);
    font-size: 0.875rem;
    line-height: 2;
}

.main{
    .item{
        font-size: 0.75rem;
        .label,.info{
            line-height: 2;
        }

        display: flex;
        .label{
            width:30%;
        }
        .info{
            width: 60%;
        }
    }
}
</style>